import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PrivilegeItem, ReceiptHero } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedCorporation } from 'utils/corporation-util';

interface UIReceiptHero extends ReceiptHero {
  deleted: boolean;
}

@autoinject
export class PosReceiptHeroList {
  private fieldList: FieldSpec[] = [
    { key: "apiKey", header: "receiptHero.apiKey", type: "text" },
    { key: "showQrReceipt", header: "receiptHero.showQrReceipt", type: "boolean", },
    { key: "showQrInPaymentTerminal", header: "receiptHero.showQrInPaymentTerminal", type: "boolean", },
    { key: "namespace", header: "receiptHero.namespace", type: "text" },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];
  private readonly defaults: { [key: string]: string; } = { "deleted": " " };
  private itemList: UIReceiptHero[] = [];
  private privilege?: PrivilegeItem;

  constructor(private readonly api: MyHttpApi, private readonly router: Router) {
  }

  async activate() {
    let { clients, businessGroups, corporations } = await getAllAccessLevelsViaClient(this.api);
    this.privilege = getSelectedCorporation();
    this.fieldList = [...this.fieldList,
    { key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
    { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
    ];
    await this.search();
  }

  async search() {
    if (!this.privilege) {
      return;
    }
    const itemList = await this.api.receiptHeroList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.filter(x => !x.supersededById).map(x => ({ ...x, deleted: !!x.deleteTime }));
  }

  rowCall(key: string, row?: UIReceiptHero) {
    this.router.navigateToRoute(this.editUrl, { id: row?.id });
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
