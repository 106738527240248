import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { DayOfWeek, IdName, MyHttpApi, PrivilegeItem, SalesRestrictionTimeUpdateRequest, SalesRestrictionUpdateRequest } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName, supersededText } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

export interface UISalesRestrictionTime {
  hourStart?: number;
  hourEnd?: number;
  dayOfWeek?: DayOfWeek;
}

interface UISalesRestrictionTimeSpecifyRows extends IdName {
  rows: UISalesRestrictionTime[];
}

interface SalesRestrictionSpecifyRequestTorso {
  clientId: number;
  name?: string;
}

interface SalesRestrictionSpecifyCancel extends SalesRestrictionSpecifyRequestTorso {
  rows: UISalesRestrictionTime[];
}

@autoinject
export class PosSalesRestrictionEdit {
  private salesRestriction: SalesRestrictionUpdateRequest = {
    name: "",
    abbreviation: "",
    activationBased: false,
    minimumPermission: 'WAITER',
    type: "CLIENT",
    delete: false,
    salesRestrictionTimes: [],
  };
  private rows: UISalesRestrictionTime[] = [];
  private specifyRows: UISalesRestrictionTimeSpecifyRows[] = [];
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private typeAndName = "";
  private readonly actorPermissionList = [
    { id: "ADMIN", name: this.i18n.tr("ActorPermission.ADMIN") },
    { id: "HEAD", name: this.i18n.tr("ActorPermission.HEAD") },
    { id: "WAITER", name: this.i18n.tr("ActorPermission.WAITER") },
  ];
  translationsDayOfWeek: { [key: string]: string; }[] = [];
  private canEdit = true;
  private canSpecify = false;
  private superseded = false;

  // * Used to specify, which client is currently being targeted
  private specifyRequest?: SalesRestrictionSpecifyRequestTorso = undefined;
  // * In case of specify-cancel, restore previous state
  private specifyRequestForCancel?: SalesRestrictionSpecifyCancel = undefined;
  // * Value from unused client list
  private specifySelectClientValue?: number;
  // * List of clients that the actor can see within the BG
  private clientList: IdName[] = [];

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) {
  }

  async activate(params: { id?: string, isMaster?: string; }) {
    this.isMaster = params.isMaster === "true";
    const id = params.id;
    if (id) {
      let [salesRestriction, rowResponse] = await Promise.all([
        this.api.salesRestrictionById({ id }),
        this.api.salesRestrictionListTimes({ id }),
      ]);
      this.salesRestriction = { ...salesRestriction, delete: !!salesRestriction.deleteTime, type: 'CLIENT', salesRestrictionTimes: [] };
      this.rows = rowResponse.rows;
      this.specifyRows = rowResponse.specifyRows.filter(x => x.rows.length);
      this.privilege = privilegeItemFromElement(salesRestriction);
      this.superseded = !!salesRestriction.supersededById;
    }
    if (!this.isMaster && !id) {
      this.privilege = getSelectedCorporation();
    } else if (this.isMaster) {
      this.privilege = undefined;
    }

    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

    if (this.privilege) {
      this.canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.id && this.privilege.type === 'CLIENT') {
        const bgr = await this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id });
        this.canEdit = bgr.clientSpecificSalesRestrictions == 'ALLOW';
      }
      if (this.privilege.type == 'BUSINESS_GROUP' && this.privilege.id) {
        const [clientList, bg] = await Promise.all([
          this.api.privilegeListClientsByBusinessGroupId({ businessGroupId: this.privilege.id }),
          this.api.businessGroupById({ id: this.privilege.id })
        ]);
        this.clientList = clientList;
        this.canSpecify = bg.clientSpecificSalesRestrictions != 'RESTRICT';
      }
    }
    if (this.superseded) {
      this.canEdit = this.canSpecify = false;
    }
  }

  @computedFrom("disabled", "canSpecify", "superseded")
  get disabledText() {
    let text = "";
    if (this.superseded) {
      return supersededText(this.i18n, "salesRestriction");
    } else if (!this.canEdit) {
      text = this.i18n.tr('businessGroupRestriction.readOnly');
      if (this.canSpecify) {
        text += ` ${this.i18n.tr("businessGroupRestriction.canSpecify")}`;
      }
    }
    return text;
  }

  validateRows(rows: UISalesRestrictionTime[]) {
    let itemsPerDay: { [key: string]: number; } = {};
    for (const row of rows) {
      if (row.hourEnd == row.hourStart || !row.dayOfWeek) {
        this.notify.info("salesRestriction.rowError");
        return false;
      }
      if (row.hourEnd && row.hourStart && parseInt("" + row.hourEnd) < parseInt("" + row.hourStart)) {
        this.notify.info("salesRestriction.rowErrorStartTime");
        return false;
      }
      if (row.dayOfWeek) {
        let count = itemsPerDay[row.dayOfWeek] || 0;
        count++;
        if (count > 1) {
          this.notify.info("salesRestriction.rowErrorDuplicateDow");
          return false;
        }
        itemsPerDay[row.dayOfWeek] = count;
      }
    }
    return true;
  }

  @computedFrom("clientList", "specifyRows.length")
  get unspecifiedClientList() {
    return this.clientList.filter(c => !this.specifyRows.find(r => r.id === c.id)?.rows.length);
  }

  async save(deleted: boolean) {
    if (!deleted && !this.validateRows(this.rows)) {
      return;
    }
    if (this.specifyRequest) {
      if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
        return;
      }
    }
    await this.api.salesRestrictionUpdate({
      ...this.salesRestriction,
      salesRestrictionTimes: this.rows.map(x => {
        return {
          // * salesRestrictionId will be overwritten in serverSide, hours are handled by validate
          salesRestrictionId: "",
          hourStart: x.hourStart || 0,
          hourEnd: x.hourEnd || 0,
          dayOfWeek: x.dayOfWeek!
        };
      }),
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }

  @computedFrom("salesRestriction.activationBased")
  get activationBasedObserver() {
    if (this.salesRestriction.activationBased) {
      this.salesRestriction.endDate = this.salesRestriction.startDate = undefined;
      this.salesRestriction.minimumPermission = 'WAITER';
      this.specifyRows = [];
      this.rows = [];
    }
    return "";
  }

  /** When selecting before unseen client from select-box */
  async startSpecifyEmpty() {
    let idName = this.clientList.find(x => x.id == this.specifySelectClientValue);
    if (idName) {
      await this.startSpecify(idName);
    }
  }

  /** Open the client for modification, save the previous specify if available */
  async startSpecify(idName: IdName) {
    if (this.specifyRequest) {
      if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
        return;
      }
    }
    const clientId = idName.id;
    let foundReq = this.specifyRows.find(x => x.id === clientId);
    let salesRestrictionTimes = foundReq?.rows || [];
    const salesRestrictionTimesForCancelRequest = [...salesRestrictionTimes];
    if (!salesRestrictionTimes.length) {
      salesRestrictionTimes.push({});
    }
    let req: SalesRestrictionSpecifyRequestTorso = {
      clientId,
      name: idName.name,
    };
    if (!this.specifyRows.find(x => x.id === clientId)) {
      this.specifyRows.unshift({
        id: clientId,
        name: idName.name || "",
        rows: [{}],
      });
    }
    this.specifyRequest = req;
    this.specifyRequestForCancel = { ...req, rows: salesRestrictionTimesForCancelRequest.map(x => ({ ...x })) };
    this.specifySelectClientValue = undefined;
  }

  /** Cancel the operation, restore server data that we had while loading the page originally */
  cancelSpecify() {
    let clientId = this.specifyRequest?.clientId;
    if (clientId) {
      this.specifyRequest = undefined;
      let idx = this.specifyRows.map(x => x.id).indexOf(clientId);
      // * Check if we had previous data and if did have rows
      let cr = this.specifyRequestForCancel;
      if (cr?.rows.length) {
        let originalSpecifyRow = {
          id: cr.clientId,
          name: cr.name || "",
          rows: cr.rows,
        };
        this.specifyRows.splice(idx, 1, originalSpecifyRow);
      } else {
        // * Item did not exists before, remove fully from list
        this.specifyRows = this.specifyRows.filter(x => x.id !== clientId);
      }
    }
    this.specifyRequestForCancel = undefined;
  }

  /** Save the specify */
  async saveSpecify(clientId: number) {
    let data = this.specifyRows.find(x => x.id == clientId);
    if (!data || !this.salesRestriction.id || !this.validateRows(data.rows)) {
      return false;
    }
    await this.api.salesRestrictionSpecify({
      elementId: this.salesRestriction.id,
      clientId: data.id,
      salesRestrictionTimes: (<unknown[]>data.rows) as SalesRestrictionTimeUpdateRequest[],
    });
    this.specifyRequest = undefined;
    return true;
  }

  /** Just save a set of empty rows */
  async deleteSpecify() {
    const clientId = this.specifyRequest?.clientId;
    if (this.salesRestriction.id && clientId) {
      await this.api.salesRestrictionSpecify({
        elementId: this.salesRestriction.id,
        clientId,
        salesRestrictionTimes: [],
      });
    }
    this.specifyRequest = undefined;
    this.specifyRows = this.specifyRows.filter(x => x.id !== clientId);
  }
}
