import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, MyHttpApi, PrivilegeItem, SalesRestriction } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedCorporation } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UISalesRestriction extends SalesRestriction {
  deleted: boolean;
}

@autoinject
export class PosSalesRestrictionList {
  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "abbreviation", header: "common.abbreviation", type: "text", },
    { key: "activationBased", header: "salesRestriction.activationBased", type: "boolean", },
    { key: "startDate", header: "salesRestriction.startDate", type: "date", format: "DD.MM.YYYY" },
    { key: "endDate", header: "salesRestriction.endDate", type: "date", format: "DD.MM.YYYY" },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];
  private readonly defaults: { [key: string]: string; } = { "deleted": " " };
  private itemList: UISalesRestriction[] = [];
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private clients: { [key: number]: Client; } = {};
  private businessGroups: { [key: number]: BusinessGroup; } = {};

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly notify: Notify, private readonly i18n: I18N) {
  }

  async activate(params: { isMaster: string; }) {
    let [{ clients, businessGroups, corporations }] = await Promise.all([
      getAllAccessLevelsViaClient(this.api),
    ]);
    this.clients = clients;
    this.businessGroups = businessGroups;

    this.isMaster = params.isMaster === "true";
    if (!this.isMaster) {
      this.privilege = getSelectedCorporation();
      this.fieldList = [...this.fieldList,
      { key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
      { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
      { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
      ];
    }
    await this.search();
  }

  async search() {
    if (!this.isMaster && !this.privilege) {
      return;
    }
    const itemList = await this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.filter(x => !x.supersededById).map(x => ({...x, deleted: !!x.deleteTime}));
  }

  async rowCall(key: string, row?: SalesRestriction) {
    if (this.privilege?.type === 'CLIENT' && !row?.id && !this.isMaster) {
      const bgId = this.clients[this.privilege.id].businessGroupId || 0;
      if (bgId && this.businessGroups[bgId].clientSpecificSalesRestrictions != "ALLOW") {
        this.notify.info("businessGroupRestriction.notAllowed", { element: this.i18n.tr("businessGroupRestriction.salesRestriction") });
        return;
      }
    }
    this.router.navigateToRoute(this.editUrl, { id: row?.id, isMaster: this.isMaster });
  }

  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
