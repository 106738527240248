import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, PrivilegeItem, ReceiptHero, ReceiptHeroUpdateRequest } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';

@autoinject
export class PosReceiptHeroEdit {
  private request: ReceiptHeroUpdateRequest = {
    delete: false,
    apiKey: "",
    showQrReceipt: true,
    showQrInPaymentTerminal: false,
    type: 'CLIENT',
  };
  private privilege?: PrivilegeItem;
  private typeAndName = "";
  private disabled = true;
  private superseded = false;


  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
  }

  async activate(params: { id?: string; }) {
    if (params.id) {
      const rh = await this.api.receiptHeroById({ id: params.id });
      this.elementToRequest(rh);
    } else if (!params.id) {
      this.privilege = getSelectedCorporation();
      // * Try to be sneaky and try to load any possibly deleted for editing. This will keep DB cleaner with only one element per privilegeItem
      if (this.privilege) {
        const maybeRh = await this.api.receiptHeroByPrivilege({ type: this.privilege.type, privilegeId: this.privilege.id });
        this.elementToRequest(maybeRh);
      }
    }
    if (this.privilege) {
      const canEdit = await this.api.privilegeCanEdit(this.privilege);
      this.disabled = !canEdit || this.superseded;
    }
    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
  }

  elementToRequest(rh?: ReceiptHero) {
    if (!rh) {
      return;
    }
    this.request = { ...rh, delete: false, type: 'CLIENT' };
    this.privilege = privilegeItemFromElement(rh);
    this.superseded = !!rh.supersededById;
  }

  async save(deleted: boolean) {
    await this.api.receiptHeroUpdate({
      ...this.request,
      privilegeId: this.privilege?.id,
      type: this.privilege?.type || 'CLIENT',
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
